<div class="text-center">
  <a class="logo mb-3" href="#" >
    <img src="../../../../assets/images/Manage_Your_Food_Program_LOGO_FINAL.png" height="100px">
  </a>
</div>

<nb-auth-block >
  <h2 class="title mt-3">Sign In</h2>
  <small class="form-text sub-title">Hello! Sign in with your username or email</small>

  <form (ngSubmit)="login()" #form="ngForm" autocomplete="nope">

    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger">
      <div><strong>Failed to login!</strong></div>
      <div *ngFor="let error of errors">{{ error }}</div>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success">
      <div><strong>Success!</strong></div>
      <div *ngFor="let message of messages">{{ message }}</div>
    </nb-alert>

    <div class="form-group">
      <label for="input-email" class="sr-only">Email address</label>
      <input nbInput
             [(ngModel)]="user.email"
             #email="ngModel"
             name="email"
             id="input-email"
             pattern=".+@.+\..+"
             placeholder="Email address"
             autofocus
             fullWidth
             [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.email.required')">
      <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
        Email is required!
      </small>
      <small class="form-text error"
             *ngIf="email.invalid && email.touched && email.errors?.pattern">
        Email should be the real one!
      </small>
    </div>

    <div class="form-group">
      <label for="input-password" class="sr-only">Password</label>
      <input nbInput
             [(ngModel)]="user.password"
             #password="ngModel"
             name="password"
             type="password"
             id="input-password"
             placeholder="Password"
             fullWidth
             [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.password.required')"
             [minlength]="getConfigValue('forms.validation.password.minLength')"
             [maxlength]="getConfigValue('forms.validation.password.maxLength')">
      <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
        Password is required!
      </small>
      <small
        class="form-text error"
        *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
        Password should contains
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </small>
    </div>

    <div class="form-group accept-group col-sm-12">
      <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
      <a class="forgot-password" routerLink="./request-password">Forgot Password?</a>
      
    </div>

    <button nbButton
            status="primary"
            [nbSpinner]="showSpinner" nbSpinnerStatus="danger"
            fullWidth
            [disabled]="submitted || !form.valid"
            [class.btn-pulse]="submitted">
      Sign In
    </button>

  </form>
</nb-auth-block>
